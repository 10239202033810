import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalizationInfo } from 'project.config';
import styles from './Brow.module.scss';
import ProductInfo from './ProductInfo/index';
import { IBrowProps } from './types/IBrowProps';
import { composeBuyButtonLink, displayBuyNowCTA, extractDropdownMenu } from './utils';
import {
  PDP_TAB_TYPES,
  PDP_TAB_EVENT_KEYS,
  PDP_TAB_TYPES_FOR_LOCAL,
} from '../../../../helpers/constants/pdpBrowConstants';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { getBrowNavItemLink } from '../../../../helpers/utils/getBrowNavItemLink';
import { desktop, mediumDesktop, tablet } from '../../../../helpers/utils/screensizes';
import { TLocalizationInfo } from '../../../../types/TLocalizationInfo';

const cx = classNames.bind(styles);

const Brow = ({ data, product, onSelectTab, isPdpOverview: isPdpOverview, activeKey }: IBrowProps) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale);
  const [isTablet] = useMediaQuery(tablet);
  const isMobile = !isTablet;
  const [isDesktop] = useMediaQuery(desktop);
  const [isMediumDesktop] = useMediaQuery(mediumDesktop);
  const [navOpen, setNavOpen] = useState(false);
  const dropdownMenu = extractDropdownMenu(data.topLevelBrowNavigationBlock);
  const isShowBuyButton = displayBuyNowCTA(product, isPdpOverview, localizationInfo.locale);
  const [isMoreTabActive, setIsMoreTabActive] = useState(false);
  const [showMoreTab, setShowMoreTab] = useState(false);
  const [topLevelMenuLinks, setTopLevelMenuLinks] = useState<any[]>(dropdownMenu);
  const [moreMenuLinks, setMoreMenuLinks] = useState<any[]>([]);
  const linksToShowOnTablet = isShowBuyButton ? 2 : 3;
  const linksToShowOnDesktop = isShowBuyButton ? 3 : 4;
  const linksToShowOnMediumDesktop = isShowBuyButton ? 4 : 5;

  const buyButtonLink = composeBuyButtonLink({
    currentPath: router.asPath,
    locale: localizationInfo.appLocale,
    lang: localizationInfo.language,
    sku: product.key,
    productSlug: product.slug,
  });

  const toggleNav = (eventKey?: string, isMoreTab?: boolean) => {
    if (isMobile) {
      setNavOpen((state) => !state);
    }

    setIsMoreTabActive(isMoreTab || false);

    if (eventKey) {
      onSelectTab(eventKey);
    }
  };

  useEffect(() => {
    const noMoreTab = () => {
      setTopLevelMenuLinks(dropdownMenu);
      setMoreMenuLinks([]);
      setIsMoreTabActive(false);
      setShowMoreTab(false);
    };
    if (isMediumDesktop) {
      if (dropdownMenu.length > linksToShowOnMediumDesktop + 1) {
        setTopLevelMenuLinks(dropdownMenu.slice(0, linksToShowOnMediumDesktop));
        setMoreMenuLinks(dropdownMenu.slice(linksToShowOnMediumDesktop));
        setIsMoreTabActive(
          Boolean(dropdownMenu.slice(linksToShowOnMediumDesktop).filter((i) => i.eventKey === activeKey).length),
        );
        setShowMoreTab(true);
      } else {
        noMoreTab();
      }
    } else if (isDesktop) {
      if (dropdownMenu.length > linksToShowOnDesktop + 1) {
        setTopLevelMenuLinks(dropdownMenu.slice(0, linksToShowOnDesktop));
        setMoreMenuLinks(dropdownMenu.slice(linksToShowOnDesktop));
        setIsMoreTabActive(
          Boolean(dropdownMenu.slice(linksToShowOnDesktop).filter((i) => i.eventKey === activeKey).length),
        );
        setShowMoreTab(true);
      } else {
        noMoreTab();
      }
    } else if (isTablet) {
      if (dropdownMenu.length > linksToShowOnTablet + 1) {
        setTopLevelMenuLinks(dropdownMenu.slice(0, linksToShowOnTablet));
        setMoreMenuLinks(dropdownMenu.slice(linksToShowOnTablet));
        setIsMoreTabActive(
          Boolean(dropdownMenu.slice(linksToShowOnTablet).filter((i) => i.eventKey === activeKey).length),
        );
        setShowMoreTab(true);
      } else {
        noMoreTab();
      }
    } else {
      noMoreTab();
    }
  }, [
    activeKey,
    setTopLevelMenuLinks,
    setMoreMenuLinks,
    setIsMoreTabActive,
    setShowMoreTab,
    isMediumDesktop,
    isDesktop,
    isTablet,
    linksToShowOnMediumDesktop,
    linksToShowOnDesktop,
    linksToShowOnTablet,
  ]);

  return (
    <Navbar expand="md" className={`${styles.brow}`} data-testid="brow">
      <div className={styles.productInfo}>
        <ProductInfo
          data-testid="pdp-brow-brand"
          product={product}
          onSelectTab={onSelectTab}
          isShowProductName={true}
          locale={localizationInfo.appLocale}
          isMobile={false}
          isPdpOverview={isPdpOverview}
        />
      </div>

      <div
        aria-controls="brow-nav"
        className={cx(`${styles.mobileMenuButton} navbar-toggler`, {
          collapsed: !navOpen,
        })}
        onClick={() => toggleNav()}
      >
        Menu
        <span className={`${styles.browMobileIcon} ${styles.open}`} />
        <span className={`${styles.browMobileIcon} ${styles.close}`} />
      </div>

      <Navbar.Collapse id="brow-nav" className={`justify-content-end ${styles.browNav}`} in={navOpen}>
        {Boolean(dropdownMenu.length) && (
          <Nav
            className={cx('navBarMenu', { hasBuyNowButton: isShowBuyButton })}
            data-testid="brow-menu"
            role="tablist"
          >
            {topLevelMenuLinks.map((menuItem) => {
              return (
                <Nav.Item key={`${menuItem.uid}-nav-item`} role="tab">
                  <Nav.Link
                    eventKey={menuItem.eventKey}
                    onClick={() => toggleNav(menuItem.eventKey, false)}
                    data-testid={`menu-link-${menuItem.uid}`}
                    href={getBrowNavItemLink(router.asPath, menuItem.eventKey, isPdpOverview)}
                    className={styles.browNavItem}
                    role="link"
                  >
                    {formatMessage({
                      //@ts-ignore
                      id: `${PDP_TAB_TYPES_FOR_LOCAL[menuItem.title]}.title`,
                      defaultMessage: menuItem.title,
                    })}
                  </Nav.Link>
                </Nav.Item>
              );
            })}

            {showMoreTab && (
              <Dropdown
                className={cx(`d-none d-md-block ${styles.moreTab}`, {
                  hasBuyNowButton: isShowBuyButton,
                })}
                data-testid="more-tab"
                role="tab"
              >
                <Dropdown.Toggle id={PDP_TAB_EVENT_KEYS.MORE} className={isMoreTabActive ? 'active' : ''}>
                  <span>
                    {formatMessage({
                      //@ts-ignore
                      id: `${PDP_TAB_TYPES_FOR_LOCAL[PDP_TAB_TYPES.MORE]}.title`,
                      defaultMessage: PDP_TAB_TYPES.MORE,
                    })}
                    <i className={styles.navigateDownIcon} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  role="tablist"
                  renderOnMount={true}
                  className={styles.moreDropdown}
                  data-testid="more-dropdown-menu"
                >
                  {moreMenuLinks.map((menuItem) => (
                    <Dropdown.Item
                      key={`${menuItem.uid}-dropdown`}
                      eventKey={menuItem.eventKey}
                      onClick={() => toggleNav(menuItem.eventKey, true)}
                      data-testid={`sub-menu-link-${menuItem.uid}-dropdown`}
                      role="tab"
                      href={getBrowNavItemLink(router.asPath, menuItem.eventKey, isPdpOverview)}
                    >
                      {formatMessage({
                        //@ts-ignore
                        id: `${PDP_TAB_TYPES_FOR_LOCAL[menuItem.title]}.title`,
                        defaultMessage: menuItem.title,
                      })}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {isShowBuyButton && (
              <Nav.Item className={`d-none d-md-block ${styles.buyButtonBLock}`} role="tab">
                <Nav.Link className="nav-link p-0" href={buyButtonLink} key={`${data.uid}-buy-button`} role="link">
                  <span className={`btn btn-yellow rounded-pill ${styles.buyButton}`}>
                    {data.buttonText || 'Buy Now'}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        )}
      </Navbar.Collapse>

      {isPdpOverview && (
        <div role="presentation" className={styles.mobileShopTab}>
          <div className={styles.productInfo}>
            <ProductInfo
              data-testid="pdp-brow-brand"
              product={product}
              onSelectTab={onSelectTab}
              isShowProductName={false}
              locale={localizationInfo.appLocale}
              isMobile={true}
              isPdpOverview={isPdpOverview}
            />
          </div>

          {isShowBuyButton && (
            <a
              className={`btn btn-yellow rounded-pill ${styles.buyButton}`}
              href={buyButtonLink}
              key={`${data.uid}-buy-button`}
            >
              {data.buttonText || 'Buy Now'}
            </a>
          )}
        </div>
      )}
    </Navbar>
  );
};

export default Brow;
