import { TNikonMoney } from './TNikonMoney';
import { Attributes } from '../../types/product/Attributes';
import { BundleGroup } from '../../types/product/BundleGroup';
import { Product } from '../../types/product/Product';
import { ReviewRatingStatistics } from '../../types/product/ReviewRatingStatistics';
import { Variant } from '../../types/product/Variant';

export type TNikonProductCategory = {
  categoryId: string;
};

export type TNikonAttributeCompatibleWithProducts = {
  typeId: string;
  id: string;
};

export type TNikonAttributeProductTypeCode = {
  key: string;
  label: string;
};

export type TNikonAttributeRegion = {
  key: string;
  label: string;
};

export type TNikonAttributeImageSensorFormat = {
  key: string;
  label: string;
};

export type TNikonAttributeImageSensorType = {
  key: string;
  label: string;
};

export type TNikonAttributePictureControl = {
  key: string;
  label: string;
};

export type TNikonAttributeShipmentWeightImperialUnit = {
  key: string;
  label: string;
};

export type TNikonAttributes = Attributes & {
  productNumber: string;
  isAvailableForSale: boolean;
  canBePreordered: boolean;
  altPreorderMessaging: string;
  altBackorderMessaging: string;
  isRefurbished: boolean;
  isReviewable?: boolean;
  reviewableDate?: Date;
  compatibleWithProducts: TNikonAttributeCompatibleWithProducts[];
  title: string;
  isWhereToBuyEnabled: boolean;
  isFinancingEligible: boolean;
  productTypeCode: TNikonAttributeProductTypeCode[];
  region: TNikonAttributeRegion[];
  Type: string;
  lensMount: TNikonAttributeShipmentWeightImperialUnit[];
  pictureAngle: string;
  effectivePixels: number;
  sensorSizeW: number;
  sensorSizeL: number;
  imageSensorFormat: TNikonAttributeImageSensorFormat[];
  imageSensorType: TNikonAttributeImageSensorType[];
  totalPixels: number;
  dustReductionSystem: string;
  dustOffReferencePhoto: string;
  imageAreaLFX: string;
  imageAreaMFX: string;
  imageAreaSFX: string;
  imageAreaLDX: string;
  imageAreaMDX: string;
  imageAreaSDX: string;
  imageAreaL169: string;
  imageAreaM169: string;
  imageAreaS169: string;
  fileFormatStillImages: string;
  storageMedia: string;
  cardSlot: string;
  fileSystem: string;
  viewfinder: string;
  viewfinderFrameCoverage: string;
  viewfinderMagnification: string;
  viewfinderEyepoint: string;
  viewfinderDiopterAdjustment: string;
  eyeSensor: string;
  depthOfFieldControl: string;
  lensCompatibilityAtAGlance: string;
  compatibleLenses: string;
  shutterSpeed: string;
  fastestShutterSpeed: string;
  slowestShutterSpeed: string;
  flashSyncSpeed: string;
  bulbShutterSetting: boolean;
  shutterReleaseModes: string;
  frameAdvanceRate: string;
  continuousShootingOptions: string;
  selfTimer: string;
  silentShooting: boolean;
  topContinuousShootingSpeeds: string;
  exposureMeteringSystem: string;
  meteringMethod: string;
  meteringRange: string;
  exposureModes: string;
  exposureCompensation: string;
  exposureBracketing: boolean;
  exposureLock: string;
  pictureControl: TNikonAttributePictureControl[];
  multipleExposure: string;
  isoSensitivity: string;
  lowestStandardIsoSensitivity: number;
  highestStandardIsoSensitivity: number;
  lowestExpandedIsoSensitivity: string;
  highestExpandedIsoSensitivity: string;
  longExposureNoiseReduction: boolean;
  highIsoNoiseReduction: string;
  activeDLighting: string;
  activeDLightingBracketing: boolean;
  addToCartAccessories: TNikonProduct[];
  singlePointAfMode: string;
  dynamicAfMode: string;
  autoAreaAfMode: boolean;
  autofocusSystem: string;
  detectionRange: string;
  lensServo: string;
  focusPoint: string;
  afAreaMode: string;
  focusLock: string;
  focusModes: string;
  maximumAutofocusAreasPoints: string;
  vibrationReduction: string;
  autofocusFineTune: string;
  flashBracketing: string;
  xSyncSpeed: string;
  topFpHighSpeedSync: string;
  flashControl: string;
  flashSyncModes: string;
  flashCompensation: string;
  flashReadyIndicator: string;
  accessoryShoe: string;
  nikonCreativeLightingSystem: string;
  flashSyncTerminal: string;
  whiteBalance: string;
  whiteBalanceBracketing: string;
  liveViewShooting: string;
  movieMetering: string;
  movieMaximumRecordingTime: string;
  movieFileFormat: string;
  movieVideoCompression: string;
  movieAudioRecordingFormat: string;
  movieFrameSizePixelsAndFrameRateRawVideo: string;
  movie: string;
  '8kUhd': boolean;
  '4kUltraHighDefinitionUhd': boolean;
  fullHdMovies1080p: boolean;
  slowMotionVideo: boolean;
  movieAudio: string;
  movieISO: string;
  movieActiveDLighting: string;
  timeCode: string;
  movieLogGammaOutput: string;
  movieeVR: boolean;
  movieHDMIOutput: string;
  movieFocusPeaking: boolean;
  movieHighlightDisplay: boolean;
  timeLapseMovie: string;
  aFMovie: string;
  rawVideoOption: boolean;
  monitorSize: number;
  monitorResolution: number;
  monitorType: string;
  touchscreen: boolean;
  monitorAngleOfView: string;
  monitorAdjustments: string;
  virtualHorizonCameraIndicator: string;
  playbackFunctions: string;
  inCameraImageEditing: string;
  imageComment: string;
  voiceMemoFunction: string;
  interface: string;
  ethernet: string;
  wiFiFunctionality: string;
  smartDeviceAppConnectivity: string;
  wirelessConnectivity: boolean;
  wirelessTransferToPc: boolean;
  bluetooth: string;
  gps: string;
  supportedGnsSystems: string;
  dataAcquired: string;
  clockSynchronization: string;
  trackLogs: string;
  logInterval: string;
  maximumLogRecordingTime: string;
  logDeletion: string;
  saveLoadCameraSettings: boolean;
  webcamUtilityCompatible: boolean;
  totalCustomSettings: number;
  myMenu: string;
  recentSettings: boolean;
  supportedLanguages: string;
  dateAndTimeAndDaylightSavingsTimeSettings: string;
  batteryBatteries: string;
  movies: string;
  acAdapter: string;
  batteryCharger: string;
  tripodSocket: string;
  approxDimensionsWidthIn: number;
  approxDimensionsWidthMm: number;
  approxDimensionsHeightIn: number;
  approxDimensionsHeightMm: number;
  approxDimensionsDepthIn: number;
  approxDimensionsDepthMm: number;
  approxWeightOz: number;
  approxWeightG: number;
  operatingEnvironment: string;
  suppliedProducts: TNikonProduct[];
  relatedAccessories: TNikonProduct[];
  shipmentWeightImperialValue: number;
  shipmentWeightImperialUnit: TNikonAttributeShipmentWeightImperialUnit;
  purchaseLimit?: number;
  color?: { key: string; label: string };
  kitItems?: TNikonProduct[];
  bundleWithList?: TNikonVariant[];
  promoMessage?: string;
  focalLength?: number;
  newUntil?: string;
  bodyOnly?: boolean;
};

export type TNikonPreBackorderChannel = {
  isOnStock: boolean;
  availableQuantity: number;
  channelId: string;
};

export enum EAddToCartStatus {
  ADD_TO_CART = 'ADD_TO_CART',
  PREORDER = 'PREORDER',
  BACKORDER = 'BACKORDER',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export enum ENikonProductTypeKeys {
  SPORT_OPTICS_FIELDSCOPE = 'pt-sport-optics_fieldscope',
  ACCESSORIES = 'pt-accessories',
  PARTS = 'pt-parts',
  LENSES = 'pt-lenses',
  SPORT_OPTICS_BINOCULARS = 'pt-sport-optics_binoculars',
  FLASHES = 'pt-flashes',
  SPORT_OPTICS_RANGEFINDER = 'pt-sport-optics_rangefinder',
  NIKON1_CAMERAS = 'pt-nikon1-cameras',
  DIGITAL_CAMERAS = 'pt-digital-cameras',
  ACTION_CAMERAS = 'pt-action-cameras',
  MIRRORLESS_CAMERAS = 'pt-mirrorless-cameras',
  SLR_CAMERAS = 'pt-slr-cameras',
  SOFTWARE = 'pt-software',
  BOOKS = 'pt-books',
  CLASSES = 'pt-classes',
  FILM_CAMERAS = 'pt-film-cameras',
  FLASHES_NIKON1 = 'pt-flashes-nikon1',
}

export type TNikonVariant = Variant & {
  id: string;
  sku: string;
  images: string[];
  metaImages?: string[];
  attributes: TNikonAttributes;
  price: TNikonMoney;
  isOnStock: boolean;
  availableQuantity: number;
  version: number;
  swatchImage: string;
  addToCartStatus?: EAddToCartStatus;
  preBackorderChannel?: TNikonPreBackorderChannel;
  productName?: string;
  discounts?: string[];
};

export type TNikonProduct = Product & {
  key: string;
  productId: string;
  name: string;
  slug: string;
  description: string;
  categories: TNikonProductCategory[];
  variants: TNikonVariant[];
  reviewRatingStatistics: ReviewRatingStatistics;
  bundleAndSave?: BundleGroup[];
  isArchived: boolean;
  searchKeywords?: string[];
  metaTitle?: string;
  metaDescription?: string;
  productTypeKey?: ENikonProductTypeKeys;
};
